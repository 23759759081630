
.processList {
    float: left;
    margin: 10px 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.processList li {
    float: left;
    margin-right: 10px;
    cursor: pointer;
}

.processList li a {
    float: left;
    font-size: 10px;
    font-family: 'Muli', sans-serif;
    line-height: 12px;
    text-transform: uppercase;
    padding-left: 63px;
    margin-bottom: 15px;
    position: relative;
    font-weight: 300;
    letter-spacing: 2px;
    color: #292929;
    text-decoration: none;
}

.processList li a:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 1px;
    background: #000;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}

.processList li:hover a:before{
    transform: rotate(45deg);
}