*{
  transition: all .5s ease-in-out;
}

html{
  scroll-behavior: smooth;  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #282c34;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  display: flex;
  padding: 2rem 6rem;
  gap: 2rem;
  justify-content: center;
  text-align: left;
}

img{
  object-fit: cover;
  overflow: hidden;
  border-radius: 4px;
}

img:hover{
  transform: scale(1.05);
  border-radius: 8px;
}

@media only screen and (max-width:640px) {
  section {
      flex-direction: column;
      padding: 1rem 2rem;
  }
}