/* style.module.css */

.container {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    justify-content: flex-end;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 0px 8px;
    background: rgba(0, 0, 0, .8);
    backdrop-filter: blur(4px);
    padding: 1.5rem;
    gap: 1.5rem;
    color: whitesmoke;
    font-size: large;
    height: min-content;
    width: inherit;
}

.logo {
    height: 50px;
    object-fit: contain;
}

.navItems {
    display: none; /* Initially hide the navigation items */
    flex-direction: column;
    gap: .1rem;
}

/* Show menu class to display the items when active */
.navItems.showMenu {
    display: flex;
}

@media screen and (min-width: 720px) {
    .header {
        width: unset;
    }

    .navItems {
        display: flex; /* Show navigation items in a row on larger screens */
        flex-direction: row;
        gap: inherit;
    }

    .logo {
        height: 100px;
    }    
}

.item {
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
}

.item:hover {
    text-decoration: underline;
    color: white;
}

/* Hamburger menu icon styles */
.hamburgerMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-around;
    width: 30px; /* Adjust the width of the hamburger icon */
    height: 20px; /* Adjust the height of the hamburger icon */
}

.hamburgerIcon {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 3px;
}

/* Additional styles can be added or adjusted based on design preferences */

.hide{
    visibility: hidden;
}

.show{
    visibility: visible;
}