.sectionTitle {
    font-size: 40px;
    line-height: 64px;
    text-transform: uppercase;
    float: left;
    margin-bottom: 30px;
    width: 100%;
    color: var(--color);
    font-weight: 100;
    box-sizing: border-box;
}

.sectionTitle::after {
    display: block;
    content: '';
    background-color: var(--color);
    width: var(--underLineWidth);
    height: 8px;
    border-radius: 2px;
    margin-top: var(--underLineMargin);
}

@media only screen and (max-width:640px) {
    .sectionTitle {
        font-size: 30px;
        line-height: 34px;
    }
}
